export const accent = "#f7c0c4";
export const accentMuted = "#fad5d6";

export const background = {
  light: "#F6F8FE",
  dark: "#22283f",
};

export const border = {
  light: "#CBD5E0",
  dark: "#22283f",
};

export const font = {
  sans: {
    family: "DM Serif Display",
    weight: 400,
  },
};

export const type = {
  default: "#262c42",
  dark: "#22283f",
  muted: "#E2E8F0",
  link: "#c69197",
};
